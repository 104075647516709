import gql from "graphql-tag";

export const GET_MANAGEMENT_TASKS = gql`
  query GET_MANAGEMENT_TASKS(
    $page: Int,
		$itemsPerPage: Int,
		$organizationSlug: ShortString,
    $networkId: UUID,
		$deviceId: String,
		$podId: Int,
		$orderBy: TaskOrderBy,
		$direction: Direction,
		$search: ShortString,
		$status: [TaskStatusEnum!],
		$categories: [TaskCategoryEnum!],
		$taskTypes: [TaskTypeEnum!],
		$fromDate: DateTime,
		$toDate: DateTime,
		$taskIds: [ID!],

		$askForPagination: Boolean!,
  ){    
  	management{
  		tasks(
	    	page: $page,
				itemsPerPage: $itemsPerPage,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				deviceId: $deviceId,
				podId: $podId,
				orderBy: $orderBy,
				direction: $direction,
				search: $search,
				status: $status,
				categories: $categories,
				taskTypes: $taskTypes,
				fromDate: $fromDate,
				toDate: $toDate,
				taskIds: $taskIds,
  		) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
  			results {
	        id
	        cancelledBy{
	        	id
		        firstname
		        lastname
		        email
	        }
	        cancelledTime
	        category
	        device{
	        	id
	        	deviceId
	        	location
	        }	 
	        endTime
	        errorMessage
	        linkedObjectId
	      	network{
	      		id
	      		location
	      	}
      		organization{
      			id
      			slug
      			name
      		}
	        progress
	        requestedBy{
	        	id
		        firstname
		        lastname
		        email
	        }
	        scheduledTime
	        startTime
	        status
	        taskType   
	      }
  		}
  	}
  }
`;

export const GET_MANAGEMENT_TASK = gql`
	query GET_MANAGEMENT_TASK($taskId: ID!) {
	  management {
	    task(taskId: $taskId) {
	      id
	      startTime
	      endTime
	      status
	      category
	      created
	      progress
	      taskType
	      cancelledTime
	      cancelledBy {
	        id
	        firstname
	        lastname
	      }
	      scheduledTime
	      linkedObjectId
	      organization {
	      	id
	        name
	        slug
	      }
	      vlan{
	      	id
	      	name
	      }
	      requestedBy {
	        id
	        firstname
	        lastname
	      }
	      network {
	        id
	        location
	      }
	      errorMessage
	      device {
	        deviceId
	      }
	      parameters
	      result
	      context {
	        deviceFirmwareVersion
	        deviceOperatingMode
	      }
	    }
	  }
	}
`;