import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Container, Message, Segment } from 'semantic-ui-react';
import store from 'tools/SimpleStore.js';

import { OnBoardingLayout } from 'layouts/OnBoardingLayout';

import { ButtonGroup } from 'components/Buttons/ButtonGroup';
import { Icon } from 'components/Icon';
import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

class HomeBase extends Component{
	static defaultProps = {
		connected: null,
	}



  logout() {
    window.keycloak.logout({locale: store("locale") || "en"});
  }

	render(){
		const {
			connected,
			intl,
		} = this.props;

		return (
			<OnBoardingLayout
				className="connected"
				backButton={(
					<a className={`back-button`} href={"https://www.sentinelc.com/"}>
	          <Container>
	            <Icon name="chevron left"/>
	            <span>{intl.formatMessage({id:"generic.back"})}</span>
	          </Container>
	        </a>
				)}
			>		    		
			{connected && (
				<Fragment>
					<Message negative >
						<Icon name="warning sign"/>
						<span>{intl.formatMessage({id: 'homepage.message.connected'})}</span>
					</Message>
					<FormattedHTMLMessageFragment id="homepage.content" />
		    	<Segment>
		    		<FormattedHTMLMessageFragment id="homepage.connected.logoutOut" />
						<ButtonGroup>
							<span role="button" className="ui primary button" onClick={this.logout}>
								{intl.formatMessage({id: 'generic.logout'})}
							</span>
		      	</ButtonGroup>
		      </Segment>
		    	<Segment>
		    		<FormattedHTMLMessageFragment id="homepage.connected.gotoDashboard" />
					  <ButtonGroup>
					  	<Link className="ui primary button" to="/dashboard">{intl.formatMessage({id: 'generic.buttons.continue'})}</Link>
		      	</ButtonGroup>
		      </Segment>
				</Fragment>
			)}
			{!connected && (
				<Fragment>
					<FormattedHTMLMessageFragment id="homepage.content" />
		    	<Segment>
		    		<FormattedHTMLMessageFragment id="homepage.notConnected.connect" />
						<ButtonGroup>
							<Link className="ui primary button" to="/dashboard">{intl.formatMessage({id: 'generic.buttons.connexion'})}</Link>
		      	</ButtonGroup>
		      </Segment>
		    	<Segment>
		    		<FormattedHTMLMessageFragment id="homepage.notConnected.gotoOnboarding" />
					  <ButtonGroup>
					  	<Link className="ui primary button" to="/onboarding/start">{intl.formatMessage({id: 'generic.buttons.continue'})}</Link>
		      	</ButtonGroup>
		      </Segment>
				</Fragment>
			)}
	    </OnBoardingLayout>
		);
	}
}

export default injectIntl(HomeBase);