import gql from "graphql-tag";

export const GET_MANAGEMENT_REGISTRATION_TOKENS = gql`
  query GET_MANAGEMENT_REGISTRATION_TOKENS(
    $organizationIdsForTokens: [UUID!]
  ){
    management {
      registrationTokens(
        page: 1,
        itemsPerPage: 40,
        organizationIds: $organizationIdsForTokens,
      ){        
        pageInfo{
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          id
          allowFromIps
          organization{
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const GET_MANAGEMENT_DEVICES_LIGHT = gql`
  query GET_MANAGEMENT_DEVICES_LIGHT(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $orderBy: DeviceOrderBy,
    $direction: Direction,
    $networkId: UUID,
    $askForPagination: Boolean!,
  ){    
    management{
      devices(
        page: $page,
        itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        networkId: $networkId,
        orderBy: $orderBy,
        direction: $direction,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
        }
        results{
          id
          deviceId
          location
          operatingMode
          configurationTemplate{
            displayName
          }
          actions {
            action
            enabled
            disabledReason
          }
          network{
            router{
              id
              location
            }
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICES_HISTORY = gql`
  query GET_MANAGEMENT_DEVICES_HISTORY(
    $page: Int
    $itemsPerPage: Int
    $deviceIds: [String!]
    $types: [DeviceUpdateTypeEnum!]
    $search: ShortString
    $orderBy: DeviceChangeOrderBy
    $direction: Direction
    $askForPagination: Boolean!,
    $inventoryStatus: [InventoryStatus],
  ) {
    management {
      deviceChanges(
        page: $page,
        itemsPerPage: $itemsPerPage,
        deviceIds: $deviceIds,
        types: $types,
        search: $search,
        orderBy: $orderBy,
        direction: $direction,
        inventoryStatus: $inventoryStatus
      ) {
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results {
          type
          organizationName
          organization {
            id
            name
          }
          project{
            name
          }
          updatedOn
          updatedBy {
            id 
            firstname
            lastname
            email
            isPrivileged
            isAuditor
            organizationUsers{
              id
            }
          }
          deviceId
          apiEndpoint
          firmwareVersion
          network
          note
          inventoryStatus
          operatingMode
          changedFields {
            type
            field
            old
          }
        }
      }
    }
  }
`;

export const GET_MANAGEMENT_DEVICES = gql`
  query GET_MANAGEMENT_DEVICES(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $nameSearch: ShortString,
    $organizationSlug: ShortString,
    $operatingMode: [DeviceOperatingMode],
    $severity: [IncidentSeverityFilter!],
    $firmwareStatus: [FirmwareStatus],
    $inventoryStatus: [InventoryStatus],
    $orderBy: DeviceOrderBy,
    $direction: Direction,
    $online: Boolean,
    $networkId: UUID,
    $releaseChannels: [String!],
    $deviceIds: [String!],
    $askForTokens: Boolean!,
    $organizationIdsForTokens: [UUID!]

    $askForPagination: Boolean!,
    $askForDisks: Boolean!,
    $askForPorts: Boolean!,
    $askForRadios: Boolean!,
  ){    
    management{
      deviceConfigurationTemplates {
        name
        operatingMode
        displayName
      }
      registrationTokens(
        page: 1,
        itemsPerPage: 40,
        organizationIds: $organizationIdsForTokens,
      ) @include(if: $askForTokens){        
        pageInfo{
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          id
          allowFromIps
          organization{
            id
            name
            slug
          }
        }
      }
      devices(
        page: $page,
        itemsPerPage: $itemsPerPage,
        search: $search,
        nameSearch: $nameSearch,
        organizationSlug: $organizationSlug,
        networkId: $networkId,
        operatingMode: $operatingMode,
        severity: $severity,
        firmwareStatus: $firmwareStatus,
        inventoryStatus: $inventoryStatus,
        orderBy: $orderBy,
        direction: $direction,
        online: $online,
        releaseChannels: $releaseChannels,
        deviceIds: $deviceIds,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          deviceId
          id
          location
          machine
          countRadios
          countPorts
          inventoryStatus
          firstSeen @include(if:$askForTokens)
          lastSeen @include(if:$askForTokens)
          inProgressTasks: countTasks(status: [PENDING,STARTED])
          actions {
            action
            enabled
            disabledReason
          }
          blockingTask{
            id
            taskType
          }
          pendingChanges{
            type
          }
          isAlive
          isRebooting
          isUplinkDown
          configurationTemplate{
            name
            displayName
            operatingMode
          }
          usage
          latestDeployment{
            status
            downloadProgress
            version
            startTime
            downloadStartTime
            rebootStartTime
          }
          availableFirmware{
            version
          }
          releaseChannel
          currentFirmware{
            version
          }
          firmwareStatus
          operatingMode
          organization{
            id
            name
            slug
            enableOperations 
          }
          network{
            id
            location
          }
          ports @include(if: $askForPorts) {
            label
            id
            usage
            macAddress
            customConfig
            status {
              speed
              duplex
              carrier
              bridge
            }
          }
          radios @include(if: $askForRadios) {
            bands
            configuredChannel{
              channel
              displayName
            }
            autoSelectedChannel{
              channel
              displayName
            }
            currentChannel{
              channel
              displayName
            }
            driver
            enabled
            firmwareVersion
            id
            interface
            macAddress
            maxAps
            maxVhtBandwidth
            station {
              ssid
              psk
              tx
              rx
            }
            status
            txPower{
              label
              value
              actual
            }
          }
          disks @include(if: $askForDisks) {
            id
            linuxId
            name
            partNumber
            parentDiskName
            status
            hasServices
            bytesTotal
            bytesUsed
            bytesFree
            usage
            model
            task {
              id
            }
          }
          disks @skip(if: $askForDisks) {
            id
            usage
          }
          pods{
            id
            statusDetail
          }
          lastIncidentUpdate
          incidents(
            page:1, 
            itemsPerPage:5,
            status: [OPEN]
          ){
            pageInfo{
              itemsPerPage
            }
            results{
              id
              summary
              severity
              created
            }
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE_VLANS = gql`
  query GET_MANAGEMENT_DEVICE_VLANS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      ports{
        id
        label
        usage
        accessVlan{
          id
          vid
          name
        }
      }
      ssids{
        id
        vlan{
          id
          vid
          name
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE_DISKS = gql`
  query GET_MANAGEMENT_DEVICE_DISKS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      disks{
        id
        linuxId
        name
        partNumber
        parentDiskName
        status
        filesystemType
        actions {
          action
          enabled
          disabledReason
        }
        availableSpaceLevel
        hasVolumes
        hasServices
        bytesTotal
        bytesUsed
        bytesFree
        usage
        model
        task {
          id
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE_PORTS = gql`
  query GET_MANAGEMENT_DEVICE_PORTS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      network{
        router{
          id
          location
        }
      }
      ports {
        portNumber
        label
        id
        usage
        macAddress
        accessVlan {
          name
          id
          vid
          accessPolicy
        }
        podNetwork{
          pod{
            id
          }
          recipeNetwork{
            recipeVersion{
              recipe{
                name
              }
            }
          }
        }
        availableUsages{
          usage
          accessVlan{
            id
            name
            type
          }
        }
        customConfig
        detectedHosts {
          hostDisplayName
          badConnection
          ip
          deviceId
          hostId
          hostMacAddress
        }
        status {
          speed
          duplex
          carrier
          bridge
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE_STATS = gql`
  query GET_MANAGEMENT_DEVICE_STATS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      countOnlineHosts: countHosts(
        connectionStatus: ONLINE
        byLastConnection: true
      )
      countOfflineHosts: countHosts(
        connectionStatus: OFFLINE
        byLastConnection: true
      )
      countOnlineConnections: countConnections(isOnline: true)
      countOfflineConnections: countConnections(isOnline: false)

      countPacketCaptures(status: COMPLETED)
      countPortScans(status: COMPLETED)
      countOpenPorts
      countWifiSurveys
      countRogueSsids  

      countOPENIncidents: countIncidents(status: OPEN)
      countCLOSEDIncidents: countIncidents(status: CLOSED)
      countRESOLVEDIncidents: countIncidents(status: RESOLVED)

      countPENDINGTasks: countTasks(status: PENDING)
      countSTARTEDTasks: countTasks(status: STARTED)
      countFAILEDTasks: countTasks(status: FAILED)
      countCOMPLETEDTasks: countTasks(status: COMPLETED)
      countCANCELLEDTasks: countTasks(status: CANCELLED)
    }
  }`;

export const GET_MANAGEMENT_DEVICE_RADIOS = gql`
  query GET_MANAGEMENT_DEVICE_RADIOS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      radios {
        availableChannels {
          category
          channels {
            channel
            displayName
            availableTxPowers {
              label
              value
            }
          }
        }
        bands
        configuredChannel{
          channel
          displayName
          availableTxPowers{
            label
            value
            actual
          }
        }
        autoSelectedChannel{
          channel
          displayName
          availableTxPowers{
            label
            value
            actual
          }
        }
        currentChannel{
          channel
          displayName
          availableTxPowers{
            label
            value
            actual
          }
        }
        driver
        enabled
        firmwareVersion
        id
        interface
        macAddress
        maxAps
        maxVhtBandwidth
        station {
          ssid
          psk
          tx
          rx
        }
        status
        txPower{
          label
          value
          actual
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE_SSIDS = gql`
  query GET_MANAGEMENT_DEVICE_SSIDS(    
    $deviceId: String!,
  ){
    device(deviceId: $deviceId){
      id
      ssids{
        countHostsOnline: countHosts(connectionStatus:ONLINE, byLastConnection: true)
        name
        disabled
        id
        psk
        security
        type
        countHostsSeen: countHosts
        lastDisabled
        lastEnabled
        status{
          current
          scheduled
          since
        }
        connectedHosts : hosts(connectionStatus: ONLINE){
          results{
            lastConnection{
              isOnline
              device{
                id
              }
            }
          }
        }
        vlan{
          id
          name
        }        
        actions {
          action
          enabled
          disabledReason
        }
        devices{
          id
          location
          isAlive
          radios {
            macAddress
            configuredChannel{
              channel
              displayName
              availableTxPowers{
                label
                value
                actual
              }
            }
            bands
            enabled
          }
          ssids{
            id
            hosts{
              results{
                lastConnection{
                  isOnline
                  ssid{
                    id
                  }
                }
              }
            }
          }
        }
        schedule{
          enabled
          monday{
            type
            ranges{
              start
              end
            }
          }
          tuesday{
            type
            ranges{
              start
              end
            }
          }
          wednesday{
            type
            ranges{
              start
              end
            }
          }
          thursday{
            type
            ranges{
              start
              end
            }
          }
          friday{
            type
            ranges{
              start
              end
            }
          }
          saturday{
            type
            ranges{
              start
              end
            }
          }
          sunday{
            type
            ranges{
              start
              end
            }
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_DEVICE = gql`
  query GET_MANAGEMENT_DEVICE(
    $deviceId: String!,
    $askForDisks: Boolean!,
    $askForPods: Boolean!,
    $askForVlans: Boolean!,
    $askForPorts: Boolean!,
    $excludeConfigPresets: Boolean!,
  ){
    management{
      customConfigPresets @skip(if: $excludeConfigPresets) {
        name
        value
      }
      releaseChannels
      deviceConfigurationTemplates {
        name
        operatingMode
        displayName
      }
    }

    device(deviceId: $deviceId) {
      deviceId
      id
      location
      machine
      maxSsids
      countPorts
      inventoryStatus
      isUplinkDown
      firstSeen
      lastSeen
      addedToNetwork
      countRadios
      isAlive
      isRebooting
      firmwareStatus
      onlineSince
      vpnStatus
      architecture
      disksRefreshTime
      podSystem {
        status
        message
        refreshTime
        storageDisk {
          linuxId
          bytesUsed
          bytesTotal
          parentDiskName
          name
          partNumber
        }
      }
      configurationTemplate{
        name
        displayName
        operatingMode
      }
      usage
      wanStatus{
        ip
        ipPrefixlen
      }
      kernelVersion
      availableFirmware{
        version
      }
      currentFirmware {
        version
      }
      latestDeployment{
        status
        downloadProgress
        version
        startTime
        downloadStartTime
        rebootStartTime
      }
      releaseChannel
      registrationKey
      actions {
        action
        enabled
        disabledReason
      }
      pendingChanges{
        type
        requestedTime
        metadata
        devices{
          location
          deviceId
          operatingMode
          isAlive
        }
      }
      operatingMode
      organization{
        id
        name
        slug
        enableOperations
        enableInventory
      }
      network{
        id
        location
        router{
          id
          location
        }
        countAllDevices: countDevices
        wanProfile {        
          enabled
          name
          inputRate
          outputRate
        }
        vlans @include(if: $askForVlans){
          id
          name
          type
          vid
          management
        }
      }
      apiEndpoint
      lastChangeNote
      publicIp
      hardwareInfo{
        cpuCores
        cpuModel
        cpuThreads
        firmware
        id
        product
        totalRamKilobytes
        vendor
        version
      }
      ports @include(if: $askForPorts) {
        portNumber
        label
        id
        usage
        macAddress
        accessVlan {
          name
          id
          vid
          accessPolicy
        }
        podNetwork{
          pod{
            id
          }
          recipeNetwork{
            recipeVersion{
              recipe{
                name
              }
            }
          }
        }
        availableUsages{
          usage
          accessVlan{
            id
            name
            type
          }
        }
        customConfig
        detectedHosts {
          hostDisplayName
          badConnection
          ip
          deviceId
          hostId
          hostMacAddress
        }
        status {
          speed
          duplex
          carrier
          bridge
        }
      }
      disks @include(if: $askForDisks)  {
        id
        linuxId
        name
        partNumber
        parentDiskName
        status
        actions {
          action
          enabled
          disabledReason
        }
        availableSpaceLevel
        hasServices
        bytesTotal
        bytesUsed
        bytesFree
        usage
        model
        task {
          id
        }
      }
      disks @skip(if: $askForDisks)  {
        id
        linuxId
        name
        partNumber
        parentDiskName
        usage
      }
      pods @include(if: $askForPods) {
        id
        statusDetail
      }
      countOpenIncidents: countIncidents(status: OPEN)
    }
  }`;
